import { FC, useContext, useEffect, useState } from "react"
import { Button, Form, Spinner } from "react-bootstrap"
import { getReservationName, toggleArrayValue } from "../../lib/utils"
import { Reservation } from "../../lib/types"
import { AuthContext } from "../../App"
import { ReservationContext } from "../Reservations"

export interface FormValues {
  ids: string[]
  code: string
}

interface Props {
  slotReservations: Reservation[]
  isLoading: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

const CancelReservationForm: FC<Props> = ({
  slotReservations,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const authCtx = useContext(AuthContext)
  const reservationCtx = useContext(ReservationContext)

  // pick the reservation to cancel from context
  const _ids = reservationCtx.reservationId
    ? [reservationCtx.reservationId]
    : []

  const [memberIds, setMemberIds] = useState<string[]>(_ids)
  const [editToken, setEditToken] = useState<string>("")

  // flag to indicate if the current user is the one who is cancelling the reservation
  const isCurrentMember = slotReservations.some(
    (reservation) =>
      parseInt(reservation.member_id) === parseInt(authCtx.member_id ?? "")
  )

  // determine if cancellation code is required; code is not needed if:
  // - user is authenticated member and
  // - his/her reservation is checked
  const selectedReservations = slotReservations.filter((r) =>
    memberIds.includes(r.id)
  )
  const codeNotRequired =
    isCurrentMember &&
    selectedReservations.some((r) => r.member_id === authCtx.member_id)

  const validate = (): string | true => {
    if (memberIds.length === 0) {
      return "Please select at least one member to cancel"
    }
    if (!codeNotRequired && editToken.length === 0) {
      return "Please provide a cancellation code"
    }
    return true
  }

  const isValid = validate() === true

  const handleSubmit = () => {
    onSubmit({ ids: memberIds, code: editToken })
  }

  useEffect(() => {
    if (codeNotRequired && editToken.length > 0) {
      setEditToken("")
    }
  }, [codeNotRequired, editToken])

  const renderButtons = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )
    }

    return (
      <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!isValid}>
          Submit
        </Button>
      </div>
    )
  }

  const renderCancellationCodeInput = () => {
    return (
      <Form.Group className="mb-3" controlId="cancel-code">
        <Form.Label className="fw-bold">Cancellation code:</Form.Label>
        <Form.Control
          type="text"
          value={editToken}
          disabled={codeNotRequired}
          onChange={(e) => {
            setEditToken(e.target.value.toUpperCase())
          }}
        />
        <Form.Text id="passwordHelpBlock" muted>
          {codeNotRequired
            ? "Not required since you are cancelling your reservation"
            : "This code has been emailed to you with the reservation."}
        </Form.Text>
      </Form.Group>
    )
  }

  return (
    <Form>
      <Form.Group className="mb-3" controlId="cancel-players">
        <Form.Label className="fw-bold">
          <div>Select players to cancel the reservation for:</div>
        </Form.Label>
        {slotReservations.map((reservation, index) => (
          <Form.Check
            label={getReservationName(reservation)}
            type="checkbox"
            id={`cb-${index}`}
            name="member"
            value={reservation.id}
            checked={memberIds.includes(reservation.id)}
            onChange={() => {
              setMemberIds(toggleArrayValue(memberIds, reservation.id))
            }}
            key={index}
          />
        ))}
        {slotReservations.length > 1 && (
          <Button
            variant="link"
            className="px-0"
            onClick={() => setMemberIds(slotReservations.map((r) => r.id))}
          >
            Select All
          </Button>
        )}
      </Form.Group>
      {renderCancellationCodeInput()}
      {renderButtons()}
    </Form>
  )
}

export default CancelReservationForm
