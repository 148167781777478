import Slot from "./Slot"
import { getLastSlot, getMin, getUsedSlots, range } from "../lib/utils"
import { FC } from "react"
import {
  Court as CourtType,
  SlotGcalEvent,
  SlotReservation,
  SlotsConfig,
} from "../lib/types"
import EventSlot from "./EventSlot"
import { Col } from "react-bootstrap"
import useDate from "../hooks/useDate"

interface Props {
  court: CourtType
  events: SlotGcalEvent[]
  reservations: SlotReservation[]
  slotsDef: SlotsConfig
}

const Court: FC<Props> = ({ court, events, reservations, slotsDef }) => {
  const { date } = useDate()
  const usedSlots = getUsedSlots(slotsDef, court.id, events, reservations)

  const findEventBySlot = (slotNumber: number): SlotGcalEvent | undefined => {
    return events.find(
      (event) =>
        event.date === date &&
        event.slots.has(slotNumber) &&
        (event.is_special_event || event.court_id === court.id)
    )
  }

  const findReservationsBySlot = (slotNumber: number): SlotReservation[] => {
    return reservations.filter(
      (reservation) =>
        reservation.date === date &&
        reservation.slots.has(slotNumber) &&
        reservation.court_id === court.id
    )
  }

  const renderSlot = (slot: number) => {
    // first check if the slot is booked by an event
    const event = findEventBySlot(slot)
    if (event) {
      if (event.slots.size > 1 && slot !== getMin(event.slots)) {
        return null
      }

      return <EventSlot key={slot} slot={slot} event={event} />
    }

    // render non-event slot
    const slotReservations = findReservationsBySlot(slot)

    // skip if event/reservation spans multiple slots and we are not in the first one
    if (
      slotReservations &&
      slotReservations.length > 0 &&
      slotReservations[0].slots.size > 1 &&
      slot !== getMin(slotReservations[0].slots)
    ) {
      return null
    }

    return (
      <Slot
        key={slot}
        slot={slot}
        court={court}
        date={date}
        usedSlots={usedSlots}
        slotReservations={slotReservations}
        slotsDef={slotsDef}
      />
    )
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="text-nowrap">{court.label}</h5>
      </div>
      <div className="border border-secondary border-opacity-50 border-1">
        <table className="table table-striped table-bordered m-0">
          <tbody>{range(1, getLastSlot(slotsDef)).map(renderSlot)}</tbody>
        </table>
      </div>
    </>
  )
}

export default Court
