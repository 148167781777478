import moment from "moment"
import { M_DATE_FORMAT, range } from "../lib/utils"
import useConfig from "./useConfig"
import { DateContext } from "../App"
import { useContext } from "react"

const getDayOfWeek = (mDate: moment.Moment): string => {
  const today = moment().startOf("day")
  const tomorrow = moment().add(1, "days").startOf("day")

  if (mDate.isSame(today, "day")) return "Today"
  if (mDate.isSame(tomorrow, "day")) return "Tomorrow"

  return mDate.format("dddd")
}

const useDate = () => {
  const { data: config } = useConfig()
  const { date, setDate } = useContext(DateContext)
  const dates = config
    ? range(0, config.slots.advance_days - 1).map((dayInc) => {
        const mDate = moment().add(dayInc, "days")
        return {
          date: mDate.format(M_DATE_FORMAT),
          dateFmt: mDate.format("D MMM YYYY"),
          dayOfWeek: getDayOfWeek(mDate),
          mDate,
        }
      })
    : []

  const mDate = moment(date)
  return {
    date,
    mDate,
    dateFmt: mDate.format("D MMM YYYY"),
    dayOfWeek: getDayOfWeek(mDate),
    setDate,
    dates,
  }
}

export default useDate
