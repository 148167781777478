import "./UserLink.css"
import config from "../config"
import { useContext, useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import NavDropdown from "react-bootstrap/NavDropdown"
import { PiSignOutBold } from "react-icons/pi"
import {
  generateCodeVerifier,
  getAccessToken,
  sha256,
  storeAccessToken,
} from "../lib/auth-utils"
import { AuthContext, AuthDispatchContext } from "../App"
import { AuthActionKind } from "../reducer/authReducer"
import useProfile from "../hooks/useProfile"
import { Badge } from "react-bootstrap"

const getRedirectUrl = () => window.location.origin
const getClientId = () => config.clientId

const getAuthorizeUrl = async () => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = await sha256(codeVerifier)
  const searchParams = new URLSearchParams({
    redirect_url: getRedirectUrl(),
    client_id: getClientId(),
    scope: config.scope,
    code_challenge: btoa(codeChallenge),
    code_challenge_method: "S256",
    response_type: "code",
  })

  // save code verifier in local storage
  localStorage.setItem(config.localStorageVerifierKey, codeVerifier)

  return `${config.authApiBaseUrl}/authorize?${searchParams.toString()}`
}

/**
 * UserLink component
 * Renders a link to sign in or sign out. Sign-in redirects to the OneAuth server for user verification.
 * Redirects back to this component with an auth code, which is then exchanged for an access token.
 */
const UserLink = () => {
  const auth = useContext(AuthContext)
  const authDispatch = useContext(AuthDispatchContext)
  const isSignedIn = auth.token !== null
  const accessToken = getAccessToken()

  const [error, setError] = useState<string | null>(null)

  const { data: profile, error: profileError } = useProfile()

  // extract auth code from query string
  const searchParams = new URLSearchParams(window.location.search)
  const code = searchParams.get("code")
  const err = searchParams.get("error")

  // remove access token from local storage and clear the state
  const removeAccessToken = (): void => {
    localStorage.removeItem(config.localStorageAccessTokenKey)
    authDispatch({ type: AuthActionKind.REMOVE_DATA, payload: null })
  }

  const handleSignInClick = async (e: React.MouseEvent) => {
    e.preventDefault()

    // redirect to auth server for authorization
    const url = await getAuthorizeUrl()
    window.location.href = url
  }

  const handleSignOutClick = (e: React.MouseEvent) => {
    e.preventDefault()
    removeAccessToken()
  }

  const getAuthToken = async (code: string) => {
    const codeVerifier = localStorage.getItem(config.localStorageVerifierKey)
    if (!code || !codeVerifier) {
      setError("Invalid code or code verifier")
      return
    }

    const response = await fetch(config.authApiBaseUrl + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: getClientId(),
        redirect_url: getRedirectUrl(),
        code,
        code_verifier: codeVerifier,
        grant_type: "authorization_code",
      }),
    })

    const data = await response.json()
    if (data.error) {
      setError(data.error)
    } else {
      // just store the token and refresh the page, the component will pick it up from local storage
      storeAccessToken(data)
      localStorage.removeItem(config.localStorageVerifierKey)

      // remove code from search params
      searchParams.delete("code")
      window.location.search = searchParams.toString()
    }
  }

  useEffect(() => {
    if (err) {
      setError(err)
    }
  }, [err])

  useEffect(() => {
    if (accessToken && profile?.username) {
      if (
        auth.token !== accessToken ||
        auth.username !== profile.username ||
        auth.member_id !== profile.member_id
      ) {
        authDispatch({
          type: AuthActionKind.SET_DATA,
          payload: {
            token: accessToken,
            username: profile.username,
            user_id: profile.user_id,
            member_id: profile.member_id,
          },
        })
      }
    }
  }, [
    accessToken,
    profile?.username,
    profile?.user_id,
    profile?.member_id,
    authDispatch,
    auth.token,
    auth.username,
    auth.member_id,
  ])

  // if we have code, try to get auth token
  if (code && !isSignedIn) {
    getAuthToken(code)
    return <div className="flex items-center">Authorizing...</div>
  }

  const renderAnonymousLink = () => {
    return (
      <Button onClick={handleSignInClick} variant="outline-light">
        Sign In
      </Button>
    )
  }

  const renderAuthorizedLink = () => {
    const navLabel = profileError
      ? "Error"
      : profile
      ? `${profile.first_name} ${profile.last_name}`
      : "Loading..."

    const menuLabel = profileError
      ? profileError.message
      : profile
      ? profile.username
      : "..."

    return (
      <NavDropdown
        id="profile-dropdown"
        title={navLabel}
        className="fw-bold"
        align="end"
      >
        <NavDropdown.ItemText className="fw-normal">
          <div className="d-flex flex-column align-items-start gap-1">
            {menuLabel}
            {auth.member_id && <Badge bg="success">STC Member</Badge>}
            {!auth.member_id && <Badge bg="danger">non-member</Badge>}
          </div>
        </NavDropdown.ItemText>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#" onClick={handleSignOutClick}>
          <div className="d-flex align-items-center gap-2">
            <PiSignOutBold />
            <div>Sign Out</div>
          </div>
        </NavDropdown.Item>
      </NavDropdown>
    )
  }

  const renderUserLink = () => {
    if (error) {
      return <span>Error: {error}</span>
    }

    if (code) {
      return <span>Authorizing...</span>
    }

    if (isSignedIn) {
      return renderAuthorizedLink()
    }

    return renderAnonymousLink()
  }

  return <div>{renderUserLink()}</div>
}

export default UserLink
