import moment from "moment"
import {
  Reservation,
  SlotGcalEvent,
  SlotReservation,
  SlotsConfig,
} from "./types"

export const M_DATE_FORMAT = "YYYY-MM-DD"

// create range array: (4, 7) => [4, 5, 6, 7]
export const range = (start: number, end: number): number[] =>
  Array.from({ length: Math.ceil(end - start) + 1 }, (_, i) => start + i)

// convert time in the format "H:i" to minutes from midnight
// example: "08:30" -> 510
export const getTimeMins = (time: string): number => {
  const [hours, minutes] = time.split(":")
  return parseInt(hours) * 60 + parseInt(minutes)
}

// convert minutes to "H:i" format
// example: 510 -> "08:30"
export const getTimeFormatted = (mins: number): string => {
  const hours = Math.floor(mins / 60) + ""
  const minutes = (mins % 60) + ""
  return `${hours.length === 1 ? "0" + hours : hours}:${
    minutes.length === 1 ? "0" + minutes : minutes
  }`
}

// return array with start and end times for the given slot number
export const getSlotTime = (
  def: SlotsConfig,
  slotNumber: number
): [string, string] => {
  const startTime = getTimeMins(def.start_time)
  const slotStartTime = startTime + (slotNumber - 1) * def.size
  const slotEndTime = slotStartTime + def.size
  return [getTimeFormatted(slotStartTime), getTimeFormatted(slotEndTime)]
}

/**
 * Return slot number the given time falls into.
 *
 * Example:
 *  def: {
 *    "start_time": "08:00",
 *    "end_time": "21:00",
 *    "size": 30,
 *  }
 *
 *  then these will be the slots:
 *  1: 08:00 - 08:30
 *  2: 08:30 - 09:00
 *  3: 09:00 - 09:30
 *  ...
 *
 *  thus '08:15' will fall into slot 1, '09:15' will fall into slot 3, etc.
 *
 *  If time is before start_time or after end_time, we return -1.
 *  If time is at the border between two slots, we return the slot with lower number if borderLow is true, otherwise the slot with higher number.
 */
export const getSlot = (
  def: SlotsConfig,
  time: string,
  borderLow = true
): number => {
  // convert everything to minutes from the midnight
  const startTime = getTimeMins(def.start_time)
  const endTime = getTimeMins(def.end_time)
  const timeMins = getTimeMins(time)

  if (timeMins < startTime || timeMins > endTime) {
    return -1
  }

  // if time is at the border between two slots, return the slot with higher number
  const fitSlots = (timeMins - startTime) / def.size
  if (fitSlots % 1 === 0) {
    return borderLow ? Math.round(fitSlots) : Math.round(fitSlots) + 1
  }

  return Math.floor(fitSlots) + 1
}

// return number of the last slot according to the definition
export const getLastSlot = (def: SlotsConfig): number => {
  return getSlot(def, def.end_time)
}

// return array with slot numbers the event given by start/end time takes according to the definition
export const getSlots = (
  def: SlotsConfig,
  startTime: string,
  endTime: string
): Set<number> => {
  const startSlot = getSlot(def, startTime, false)
  const endSlot = getSlot(def, endTime)
  return new Set(range(startSlot, endSlot))
}

// get minimum value from a set
export const getMin = (set: Set<number>): number => {
  const values = Array.from(set)
  return Math.min(...values)
}

export const getFormattedSlotTime = (
  startTime: string,
  endTime: string
): string => {
  const mStart = moment(`${startTime}`, "HH:mm")
  const mEnd = moment(`${endTime}`, "HH:mm")
  return `${mStart.format("h:mmA")} - ${mEnd.format("h:mmA")}`
}

export const getFormattedSlotStartTime = (startTime: string): string => {
  const mStart = moment(`${startTime}`, "HH:mm")
  return `${mStart.format("h:mmA")}`
}

// return Set of slots that are accupied either by th eevent or by the existing reservation
export const getUsedSlots = (
  def: SlotsConfig,
  court: string,
  events: SlotGcalEvent[],
  reservations: SlotReservation[]
): Set<number> => {
  // process events
  const usedSlots = new Set<number>()
  events.forEach((event) => {
    if (event.is_special_event || event.court_id === court) {
      const slots = getSlots(def, event.start_time, event.end_time)
      slots.forEach((slot) => {
        usedSlots.add(slot)
      })
    }
  })

  // process reservations
  reservations.forEach((reservation) => {
    if (reservation.court_id === court) {
      const slots = getSlots(def, reservation.start_time, reservation.end_time)
      slots.forEach((slot) => {
        usedSlots.add(slot)
      })
    }
  })

  return usedSlots
}

// get how many slots are free for the given slot until the next used slot
// example: if [1, 2, 6, 10, 11] are slots that are used, then:
//   - for slot 1 there are 0 free slots
//   - for slot 3 there are 3 free slots [3, 4, 5]
//   - for slot 5 there is 1 free slot [5]
//   - for slot 8 there are 2 free slots [8, 9]
export const getFreeSlots = (
  def: SlotsConfig,
  slot: number,
  usedSlots: Set<number>
): number => {
  // remove all values from usedSlots that are smaller than the given slot
  const used = Array.from(usedSlots).filter((s) => s >= slot)

  // get the first used slot that is higher than the given slot (or the last slot of there is none)
  const nextUsed = used.length > 0 ? Math.min(...used) : getLastSlot(def) + 1

  // don't return negative values in case we got a slot number that is higher than the last slot
  return Math.max(0, nextUsed - slot)
}

export const isTimeInPast = (time: string, date: string): boolean => {
  return moment(`${date} ${time}`).isBefore(moment())
}

export const isSlotInPast = (
  slotsDef: SlotsConfig,
  slot: number,
  date: string
): boolean => {
  const slotTime = getSlotTime(slotsDef, slot)
  return isTimeInPast(slotTime[0], date)
}

export const getReservationName = (reservation: Reservation): string => {
  return `${reservation.first_name} ${reservation.last_name}`
}

export const isBmUsed = (reservations: Reservation[]): boolean => {
  return (
    reservations.find((reservation) => reservation.bm_id !== null) !== undefined
  )
}

export const toggleArrayValue = (arr: string[], value: string): string[] => {
  if (arr.includes(value)) {
    return arr.filter((v) => v !== value)
  } else {
    return [...arr, value]
  }
}

export const decodeHtml = (
  html: string,
  stripScriptTags: boolean = true
): string => {
  var txt = document.createElement("textarea")
  txt.innerHTML = html

  return stripScriptTags
    ? txt.value.replace(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
        ""
      )
    : txt.value
}
