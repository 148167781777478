import { useMutation } from "@tanstack/react-query"
import config from "../config"
import { AddReservationRequest } from "../lib/types"
import { getFetchHeaders } from "../lib/auth-utils"

const addReservation = (data: AddReservationRequest) => {
  const request = new Request(config.apiBaseUrl + "/reserve", {
    method: "POST",
    headers: getFetchHeaders(),
    body: JSON.stringify(data),
  })
  return fetch(request)
}

const useAddReservation = () => {
  return useMutation({
    mutationFn: addReservation,
  })
}

export default useAddReservation
