import {
  decodeHtml,
  getFormattedSlotTime,
  getReservationName,
  isTimeInPast,
} from "../lib/utils"
import { FC, useContext, useState } from "react"
import { GcalEvent, Reservation, SlotsConfig } from "../lib/types"
import { Alert, Button } from "react-bootstrap"
import AddReservationDialog from "./Dialog/AddReservationDialog"
import CancelReservationDialog from "./Dialog/CancelReservationDialog"
import { AuthContext } from "../App"
import { ReservationContext } from "./Reservations"
import useDate from "../hooks/useDate"

interface Props {
  event: GcalEvent
  reservations: Reservation[]
  slotsDef: SlotsConfig
}

const Event: FC<Props> = ({ event, reservations, slotsDef }) => {
  const { date } = useDate()
  const authCtx = useContext(AuthContext)
  const reservationCtx = useContext(ReservationContext)

  const [error, setError] = useState<string | null>(null)
  const [showReserveDialog, setShowReserveDialog] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const isInPast = isTimeInPast(event.start_time, date)

  // filter reservation to keep only one for the court
  const eventReservations = reservations
    .filter((reservation) => reservation.court_id === event.court_id)
    .map((reservation) => {
      // supply event start and end times
      return {
        ...reservation,
        start_time: event.start_time,
        end_time: event.end_time,
      }
    })

  // evaluate if authenticated use is booked on the event
  const userReservation = eventReservations.find(
    (r) => r.member_id === authCtx.member_id
  )

  const cancelReservation = reservationCtx.reservationId
    ? eventReservations.filter((r) => r.id === reservationCtx.reservationId)
    : null

  const maxPlayers = parseInt(event.data?.players ?? 0)

  const handleReserveClick = () => {
    setShowReserveDialog(true)
  }

  const handleReserveDialogClose = () => {
    setShowReserveDialog(false)
  }

  const handleReserveCancelClick = (id: string) => {
    reservationCtx.setReservationId(id)
    setShowCancelDialog(true)
  }

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false)
  }

  const renderOrganizer = () => {
    const data = event.data?.organizer
    if (!data) return null

    // check if the organizer is name + email, like "Foo Bar, foo@bar.com"
    const parts = data.split(",").map((s) => s.trim())
    if (parts.length === 2) {
      return (
        <tr>
          <td className="fw-bold text-end">Organizer:</td>
          <td>
            <a href={`mailto:${parts[1]}`} target="_blank" rel="noreferrer">
              {parts[0]}
            </a>
          </td>
        </tr>
      )
    }

    return (
      <tr>
        <td className="fw-bold text-end">Organizer:</td>
        <td>{data}</td>
      </tr>
    )
  }

  const renderInfo = () => {
    const data = event.data?.info
    if (!data) return null

    // allow html in the info (can be dangerous)
    return (
      <div>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: decodeHtml(data, true) }} />
      </div>
    )
  }

  const renderDescription = () => {
    const data = event.data
    return (
      <div className="m-0">
        {data.is_moderated && (
          <div>
            <div className="fw-bold">This is a moderated group</div>
            <hr />
          </div>
        )}
        <table className="">
          <tbody>
            {maxPlayers > 0 && (
              <tr>
                <td className="fw-bold text-end">Players:</td>
                <td>{maxPlayers}</td>
              </tr>
            )}
            {data.skill && (
              <tr>
                <td className="fw-bold text-end">Skill:</td>
                <td>{data.skill}</td>
              </tr>
            )}
            {data.format && (
              <tr>
                <td className="fw-bold text-end">Format:</td>
                <td>{data.format}</td>
              </tr>
            )}
            {renderOrganizer()}
          </tbody>
        </table>
        {renderInfo()}
      </div>
    )
  }

  const renderReservations = () => {
    return (
      <div className="mb-2">
        {eventReservations.map((reservation, key) => {
          return (
            <div key={key} className="d-flex gap-1">
              <div className="text-end">{key + 1}.</div>
              <div className="fw-bold">{getReservationName(reservation)}</div>
              {!authCtx.member_id && (
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handleReserveCancelClick(reservation.id)}
                  className="my-0 py-0 ms-auto text-decoration-none"
                >
                  Cancel
                </Button>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const renderActions = () => {
    if (isInPast) return null

    // if already booked, render cancel button
    if (userReservation) {
      return (
        <Button
          variant="danger"
          size="sm"
          onClick={() => handleReserveCancelClick(userReservation.id)}
        >
          Cancel Reservation
        </Button>
      )
    }

    if (
      eventReservations.length >= maxPlayers ||
      isInPast ||
      event.data.is_moderated
    ) {
      return null
    }

    return (
      <Button variant="success" size="sm" onClick={handleReserveClick}>
        Sign Up
      </Button>
    )
  }

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="text-nowrap">{event.label}</h5>
      </div>
      <div className="border border-secondary border-opacity-50 border-2">
        <div className="p-2 border-bottom fw-semibold bg-body-tertiary">
          {getFormattedSlotTime(event.start_time, event.end_time)}
        </div>
        <div className="m-2 p-3 border border-2 border-primary rounded-2">
          {renderDescription()}
        </div>
        <div className="p-3 border-top bg-body-tertiary">
          {renderReservations()}
          {error && (
            <Alert variant="danger" onClick={() => setError(null)}>
              {error}
            </Alert>
          )}
          {renderActions()}
        </div>
      </div>
      <AddReservationDialog
        show={showReserveDialog}
        maxSlots={0}
        slotsDef={slotsDef}
        date={date}
        courtId={event.court_id}
        courtLabel={event.label}
        subLabel={getFormattedSlotTime(event.start_time, event.end_time)}
        onClose={handleReserveDialogClose}
        onDone={handleReserveDialogClose}
      />
      {cancelReservation && (
        <CancelReservationDialog
          show={showCancelDialog}
          slotReservations={cancelReservation}
          courtLabel={event.label}
          onClose={handleCancelDialogClose}
          onDone={handleCancelDialogClose}
        />
      )}
    </div>
  )
}

export default Event
