import { useQuery } from "@tanstack/react-query"
import config from "../config"
import { getAccessToken, getFetchHeaders } from "../lib/auth-utils"
import { ProfileResponse } from "../lib/types"

export const fetchUserProfile = async (): Promise<ProfileResponse> => {
  const response = await fetch(`${config.authApiBaseUrl}/user`, {
    headers: getFetchHeaders(),
  })

  if (!response.ok) {
    throw new Error("Failed to fetch user profile")
  }

  return response.json()
}

const useProfile = () => {
  const token = getAccessToken()
  return useQuery({
    queryKey: ["profile", token],
    queryFn: () => fetchUserProfile(),
    enabled: !!token,
  })
}

export default useProfile
