import { useContext, useState } from "react"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import useSendFeedback from "../../hooks/useSendFeedback"
import { AuthContext } from "../../App"

interface Props {
  onDone: () => void
}

const ContactForm: React.FC<Props> = ({ onDone }) => {
  const [message, setMessage] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const authCtx = useContext(AuthContext)
  const sendFeedback = useSendFeedback()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)

    sendFeedback.mutate(data, {
      onSuccess: async (data, variables, context) => {
        const res = await data.json()
        if (res.success !== undefined && !res.success) {
          setError(res.message)
          return
        }
        // success
        setIsSubmitted(true)
      },
      onError: (error, variables, context) => {
        setError(error.message)
      },
    })
  }

  const renderButtons = () => {
    if (sendFeedback.isPending) {
      return (
        <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )
    }

    return (
      <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <Button variant="secondary" onClick={onDone}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={message.length < 3}>
          Submit
        </Button>
      </div>
    )
  }

  if (isSubmitted) {
    return (
      <div>
        <h4>Thank you!</h4>
        <p>Message was sent successfully.</p>
        <div className="d-flex justify-content-end align-items-center gap-2 mt-2">
          <Button variant="primary" onClick={onDone}>
            Close
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <h4>Contact Form</h4>
      <div className="fw-bold p-3 border-start border-2 border-primary bg-primary bg-gradient bg-opacity-10 mb-3">
        Please submit any feedback (positive or negative), report bugs, or
        request new features.
      </div>
      {error && (
        <Alert variant="danger" className="fw-bold">
          {error}
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="form-email">
          <Form.Label className="fw-bold">Your Email Address:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            defaultValue={authCtx.username ?? ""}
            placeholder="name@example.com"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="form-topic">
          <Form.Label className="fw-bold">Topic:</Form.Label>
          <Form.Select aria-label="Select Inquiry Topic" name="topic">
            <option value="general">General Inquiry / Feedback</option>
            <option value="ball-machine">Ball Machine</option>
            <option value="bug-report">Bug Report</option>
            <option value="feature-request">Feature Request</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="form-message">
          <Form.Label className="fw-bold">Message:</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={message}
            rows={10}
            onChange={(e) => setMessage(e.target.value.substring(0, 1000))}
          />
          <Form.Text id="messageHelpBlock" muted>
            {message.length}/1000
            {message.length >= 1000 && (
              <span className="text-danger"> (maximum length reached)</span>
            )}
          </Form.Text>
        </Form.Group>
        {renderButtons()}
      </Form>
    </div>
  )
}

export default ContactForm
