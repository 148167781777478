import { FC, useState } from "react"
import { Alert, Modal } from "react-bootstrap"
import { JoinReservationRequest, Reservation } from "../../lib/types"
import { useQueryClient } from "@tanstack/react-query"
import JoinReservationForm, { FormValues } from "../Form/JoinReservationForm"
import {
  getFormattedSlotTime,
  getReservationName,
  isBmUsed,
} from "../../lib/utils"
import useJoinReservation from "../../hooks/useJoinReservation"

interface Props {
  show: boolean
  slotReservations: Reservation[]
  date: string
  courtId: string
  courtLabel: string
  onClose: () => void
  onDone: () => void
}

const JoinReservationDialog: FC<Props> = ({
  show,
  onClose,
  onDone,
  date,
  courtId,
  courtLabel,
  slotReservations,
}) => {
  const joinReservation = useJoinReservation()
  const queryClient = useQueryClient()

  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = (values: FormValues) => {
    setError(null)

    // assemble form data - if maxSlots is 0, then we don't need to pass the slot_start/end
    const data: JoinReservationRequest = {
      date,
      court_id: courtId,
      emails: values.emails,
      bm_id: values.bmId,
      start_time: slotReservations[0].start_time,
      end_time: slotReservations[0].end_time,
    }

    joinReservation.mutate(data, {
      onSuccess: async (data, variables, context) => {
        const res = await data.json()
        if (res.success !== undefined && !res.success) {
          setError(res.message)
          return
        }
        // success
        queryClient.invalidateQueries({
          queryKey: ["reservations", { date }],
        })
        onDone()
      },
      onError: (error, variables, context) => {
        setError(error.message)
      },
    })
  }

  const handleOnShow = () => {
    setError(null)
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      onShow={handleOnShow}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Join Reservation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger" className="fw-bold">
            {error}
          </Alert>
        )}

        <Alert variant="primary" className="fw-bold w-100">
          <div className="mt-2 mb-2 d-flex align-items-center justify-content-between">
            <div>{courtLabel}</div>
            <div className="text-muted">
              {slotReservations.length > 0 &&
                getFormattedSlotTime(
                  slotReservations[0].start_time,
                  slotReservations[0].end_time
                )}
            </div>
          </div>
        </Alert>

        <div className="my-2 d-flex align-items-center gap-2">
          <div className="fw-bold">Reserved For:</div>
          <div>
            {slotReservations.map((r) => getReservationName(r)).join(", ")}
          </div>
        </div>
        <JoinReservationForm
          maxPlayers={4 - slotReservations.length}
          allowBm={!isBmUsed(slotReservations) && slotReservations.length === 1}
          isLoading={joinReservation.isPending}
          onSubmit={handleFormSubmit}
          onCancel={onClose}
        />
      </Modal.Body>
    </Modal>
  )
}

export default JoinReservationDialog
