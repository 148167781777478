import { useMutation } from "@tanstack/react-query"
import config from "../config"
import { JoinReservationRequest } from "../lib/types"
import { getFetchHeaders } from "../lib/auth-utils"

const joinReservation = (data: JoinReservationRequest) => {
  const request = new Request(config.apiBaseUrl + "/join", {
    method: "POST",
    headers: getFetchHeaders(),
    body: JSON.stringify(data),
  })
  return fetch(request)
}

const useJoinReservation = () => {
  return useMutation({
    mutationFn: joinReservation,
  })
}

export default useJoinReservation
