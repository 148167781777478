import { FC, useContext, useState } from "react"
import { Alert, Modal } from "react-bootstrap"
import { Reservation } from "../../lib/types"
import { useQueryClient } from "@tanstack/react-query"
import useCancelReservation from "../../hooks/useCancelReservation"
import { getFormattedSlotTime } from "../../lib/utils"
import CancelReservationForm, {
  FormValues,
} from "../Form/CancelReservationForm"
import { ReservationContext } from "../Reservations"

interface Props {
  show: boolean
  slotReservations: Reservation[]
  courtLabel: string
  onClose: () => void
  onDone: () => void
}

const CancelReservationDialog: FC<Props> = ({
  show,
  slotReservations,
  courtLabel,
  onClose,
  onDone,
}) => {
  const cancelReservation = useCancelReservation()
  const queryClient = useQueryClient()
  const reservationCtx = useContext(ReservationContext)

  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = (values: FormValues) => {
    setError(null)
    cancelReservation.mutate(
      {
        ids: values.ids,
        code: values.code,
      },
      {
        onSuccess: async (data, variables, context) => {
          const res = await data.json()
          if (res.success !== undefined && !res.success) {
            setError(res.message)
            return
          }
          // success
          queryClient.invalidateQueries({
            queryKey: ["reservations"],
          })
          onDone()
        },
        onError: (error, variables, context) => {
          setError(error.message)
        },
      }
    )
  }

  const handleHide = () => {
    setError(null)
    reservationCtx.setReservationId("")
    onClose()
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancel Reservation</Modal.Title>
      </Modal.Header>
      <Modal.Body onClick={() => error && setError(null)}>
        {error && (
          <Alert variant="danger" className="fw-bold">
            {error}
          </Alert>
        )}
        <Alert variant="primary" className="fw-bold w-100">
          <div className="mt-2 mb-2 d-flex align-items-center justify-content-between">
            <div>{courtLabel}</div>
            <div className="text-muted">
              {slotReservations.length > 0 &&
                getFormattedSlotTime(
                  slotReservations[0].start_time,
                  slotReservations[0].end_time
                )}
            </div>
          </div>
        </Alert>
        <CancelReservationForm
          slotReservations={slotReservations}
          isLoading={cancelReservation.isPending}
          onSubmit={handleFormSubmit}
          onCancel={handleHide}
        />
      </Modal.Body>
    </Modal>
  )
}

export default CancelReservationDialog
