import { useQuery } from "@tanstack/react-query"
import config from "../config"
import { BmItem } from "../lib/types"

export const fetchBallMachine = async (): Promise<BmItem[]> => {
  const response = await fetch(`${config.apiBaseUrl}/ballmachines`, {
    headers: {
      Accept: "application/json",
    },
  })

  if (!response.ok) {
    throw new Error("Failed to fetch ball machines info")
  }

  return response.json()
}

const useBallMachine = () => {
  return useQuery({
    queryKey: ["ballMachine"],
    queryFn: () => fetchBallMachine(),
  })
}

export default useBallMachine
