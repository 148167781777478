import { useQuery } from "@tanstack/react-query"
import config from "../config"
import { ConfigResponse } from "../lib/types"
import { getFetchHeaders } from "../lib/auth-utils"

export const fetchConfig = async (): Promise<ConfigResponse> => {
  const response = await fetch(`${config.apiBaseUrl}/config`, {
    headers: getFetchHeaders(),
  })

  if (!response.ok) {
    throw new Error("Failed to fetch config")
  }

  return response.json()
}

const useConfig = () => {
  return useQuery({
    queryKey: ["config"],
    queryFn: () => fetchConfig(),
  })
}

export default useConfig
