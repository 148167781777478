const config = {
  apiBaseUrl: "/api/v1",
  authApiBaseUrl: "https://auth.saanichtennisclub.org/api/v1",
  clientId: "stc-courts",
  scope: "default",
  localStorageAccessTokenKey: "stc-access-token",
  localStorageVerifierKey: "stc-code-verifier",
}

export default config
