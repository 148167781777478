import { FC, useContext, useEffect, useState } from "react"
import { Button, Form, InputGroup, Spinner } from "react-bootstrap"
import { getFormattedSlotTime, getSlotTime, range } from "../../lib/utils"
import { SlotsConfig } from "../../lib/types"
import { AuthContext } from "../../App"
import { ReservationContext } from "../Reservations"

export interface FormValues {
  emails: string[]
  bmId: string
  slots: number
}

interface Props {
  slot: number
  maxSlots: number
  slotsDef: SlotsConfig
  isLoading: boolean
  allowMultipleEmails?: boolean
  showBallMachines?: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

const AddReservationForm: FC<Props> = ({
  slot,
  maxSlots,
  slotsDef,
  isLoading,
  allowMultipleEmails = false,
  showBallMachines = false,
  onSubmit,
  onCancel,
}) => {
  const authCtx = useContext(AuthContext)
  const { bmItems } = useContext(ReservationContext)

  const [slots, setSlots] = useState<number>(Math.min(maxSlots, 2))
  const [emails, setEmails] = useState<string[]>([authCtx.username ?? ""])
  const [bmId, setBmId] = useState<string>("")

  const filteredEmails = emails
    .map((email) => email.trim())
    .filter((email) => email.length > 0)

  // cut players down to 1 if booking with ball machine
  useEffect(() => {
    if (bmId && emails.length > 1) {
      setEmails([emails[0]])
    }
  }, [bmId, emails])

  const getBookingTime = (index: number): string => {
    const startTime = getSlotTime(slotsDef, slot)[0]
    const endTime = getSlotTime(slotsDef, slot + index - 1)[1]
    return getFormattedSlotTime(startTime, endTime)
  }

  const validate = (): string | true => {
    if (filteredEmails.length === 0) {
      return "Please provide at least one member email"
    }
    if (maxSlots > 0 && (slots < 1 || slots > maxSlots)) {
      return "Please select a valid number of time slots"
    }
    return true
  }

  const isValid = validate() === true

  const handleSubmit = () => {
    onSubmit({ slots, emails: filteredEmails, bmId })
  }

  const renderButtons = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )
    }

    return (
      <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!isValid}>
          Submit
        </Button>
      </div>
    )
  }

  return (
    <Form>
      <Form.Group className="" controlId="reserve-email">
        <Form.Label className="d-flex align-items-center justify-content-between">
          <div className="fw-bold">Players:</div>
          {allowMultipleEmails && !bmId && emails.length < 4 && (
            <Button
              variant="link"
              size="sm"
              className="my-0 py-0"
              onClick={() => setEmails((emails) => [...emails, ""])}
            >
              Add player
            </Button>
          )}
        </Form.Label>
        {emails.map((email, index) => (
          <InputGroup className="mb-1" key={index}>
            <Form.Control
              type="email"
              value={email}
              placeholder="STC member email"
              onChange={(e) => {
                const values = [...emails]
                values[index] = e.target.value.trim()
                setEmails(values)
              }}
            />
            {emails.length > 1 && (
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() =>
                  setEmails((emails) => emails.filter((_, i) => i !== index))
                }
              >
                x
              </Button>
            )}
          </InputGroup>
        ))}
      </Form.Group>
      {showBallMachines && (
        <Form.Group className="my-3" controlId="bm">
          <Form.Label className="fw-bold">Ball Machine (optional):</Form.Label>
          {bmItems.map((bm) => (
            <Form.Check
              label={bm.name + (bm.in_service ? "" : " (not in service)")}
              type="checkbox"
              id={`chk-${bm.id}`}
              name="bm"
              value={bm.id}
              checked={bmId === bm.id}
              disabled={!bm.in_service}
              onChange={() => setBmId(bmId === bm.id ? "" : bm.id)}
              key={bm.id}
            />
          ))}
          <Form.Text id="bmPlayerHelpBlock" muted>
            Booking slot with a ball machine is limited to one player.
          </Form.Text>
        </Form.Group>
      )}
      {maxSlots > 0 && (
        <Form.Group className="my-3" controlId="timeslots">
          <Form.Label className="fw-bold">Duration:</Form.Label>
          {range(1, maxSlots).map((index) => (
            <Form.Check
              label={getBookingTime(index)}
              type="radio"
              id={`radio-${slot}-${index}`}
              name="slot"
              value={index}
              checked={slots === index}
              onChange={(e) => setSlots(parseInt(e.target.value))}
              key={index}
            />
          ))}
        </Form.Group>
      )}
      {renderButtons()}
    </Form>
  )
}

export default AddReservationForm
