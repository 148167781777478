import { nanoid } from "nanoid"
import config from "../config"

// === user link utils ===
interface AuthToken {
  access_token: string
  expires_in: number
}

// get locally saved access token
export const getAccessToken = (): string | null => {
  const data = JSON.parse(
    localStorage.getItem(config.localStorageAccessTokenKey) ?? "{}"
  )
  const isValid = data?.expires_at > Date.now()
  return isValid ? data.access_token : null
}

// save access token in local storage
export const storeAccessToken = (token: AuthToken): void => {
  const data = {
    ...token,
    expires_at: Date.now() + token.expires_in * 1000,
  }
  localStorage.setItem(config.localStorageAccessTokenKey, JSON.stringify(data))
}

// calculate sha-256 hash from input string
export const sha256 = async (plain: string) => {
  // encode af utf-8
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)

  // has the input string
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")

  return hashHex
}

export const generateCodeVerifier = () => {
  return nanoid(64)
}

export const getFetchHeaders = () => {
  const token = getAccessToken()
  const headers = new Headers()
  headers.append("Accept", "application/json")
  if (token) {
    headers.append("Authorization", `Bearer ${token}`)
  }
  return headers
}
