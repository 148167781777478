import { Button, Table } from "react-bootstrap"
import useBallMachine from "../hooks/useBallMachine"
import { FC } from "react"

interface Props {
  onDone: () => void
}

const BallMachineInfo: FC<Props> = ({ onDone }) => {
  const { data: bmItems } = useBallMachine()

  return (
    <div>
      <h5>Ball Machines</h5>
      <p>
        STC has several ball machines listed below for their members to use.
      </p>
      <h5>How to reserve a ball machine</h5>
      <p>
        Select a date from the drop-down on the top left and click the “Reserve”
        button in the slot you want to reserve. Select a ball machine, and
        choose a duration (30mins to 2h). If signed in, your email will be
        pre-filled.
      </p>
      <p>
        Ball machine can be reserved for a single player only. The reservation
        is linked to the player's email address and will be cancelled
        automatically if the player cancels their reservation.
      </p>
      <h5>STC ball machines</h5>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>In Service</th>
            <th>Operation Hours</th>
          </tr>
        </thead>
        <tbody>
          {bmItems?.map((bm) => (
            <tr key={bm.id}>
              <td className={bm.in_service ? "" : "text-muted"}>{bm.name}</td>
              <td>{bm.in_service ? "Yes" : "No"}</td>
              <td>
                {bm.start_time} - {bm.end_time}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p>
        Please report any ball machine issues via the Contact Form in the menu
        above (select "Ball Machine" in the Topic selector).
      </p>

      <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <Button variant="primary" onClick={onDone}>
          Close
        </Button>
      </div>
    </div>
  )
}

export default BallMachineInfo
