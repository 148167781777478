import "./Layout.css"
import { Container, Modal, Nav, Navbar } from "react-bootstrap"
import UserLink from "./UserLink"
import { PropsWithChildren, useState } from "react"
import DateMenu from "./DateMenu"
import ContactForm from "./Form/ContactForm"
import BallMachineInfo from "./BallMachineInfo"
import Howto from "./Howto"

const Layout = ({ children }: PropsWithChildren) => {
  const [showInfo, setShowInfo] = useState<
    "contactForm" | "bm" | "help" | null
  >(null)

  const handleContactFormClick = (e: React.MouseEvent) => {
    setShowInfo("contactForm")
    e.preventDefault()
  }
  const handleAboutClick = (e: React.MouseEvent) => {
    setShowInfo("help")
    e.preventDefault()
  }
  const handleBmClick = (e: React.MouseEvent) => {
    setShowInfo("bm")
    e.preventDefault()
  }

  const handleClose = () => {
    setShowInfo(null)
  }

  const renderInfo = () => {
    return (
      <Modal
        show={showInfo !== null}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {showInfo === "contactForm" && <ContactForm onDone={handleClose} />}
          {showInfo === "bm" && <BallMachineInfo onDone={handleClose} />}
          {showInfo === "help" && <Howto onDone={handleClose} />}
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div>
      <div
        className="position-sticky top-0 start-0 w-100 mb-1"
        style={{ zIndex: 990 }}
      >
        <nav className="navbar navbar-expand-lg bg-primary border-bottom">
          <Container fluid="lg">
            <a className="navbar-brand ps-1 text-white fw-semibold" href="/">
              STC Courts
            </a>
            <Navbar className="justify-content-end">
              <Nav>
                <UserLink />
              </Nav>
            </Navbar>
          </Container>
        </nav>
        <Container fluid="lg">
          <Navbar
            expand="md"
            bg="white"
            className="align-items-center mx-1 border-bottom"
          >
            <DateMenu />
            <Navbar.Toggle aria-controls="links-navbar" />
            <Navbar.Collapse id="links-navbar">
              <Nav className="ms-auto ps-3">
                <Nav.Link href="#" onClick={handleContactFormClick}>
                  Contact Form
                </Nav.Link>
                <Nav.Link href="#" onClick={handleBmClick}>
                  Ball Machines
                </Nav.Link>
                <Nav.Link href="#" onClick={handleAboutClick}>
                  How It Works
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
      <Container className="p-3 pt-0" fluid="lg">
        {children}
      </Container>
      {renderInfo()}
    </div>
  )
}

export default Layout
