import { FC, useContext, useState } from "react"
import { Button, Form, InputGroup, Spinner } from "react-bootstrap"
import { AuthContext } from "../../App"
import { ReservationContext } from "../Reservations"

export interface FormValues {
  emails: string[]
  bmId: string
}

interface Props {
  maxPlayers: number
  allowBm: boolean
  isLoading: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

const JoinReservationForm: FC<Props> = ({
  maxPlayers,
  allowBm,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const authCtx = useContext(AuthContext)
  const { bmItems } = useContext(ReservationContext)

  const [emails, setEmails] = useState<string[]>([authCtx.username ?? ""])
  const [bmId, setBmId] = useState<string>("")

  const filteredEmails = emails
    .map((email) => email.trim())
    .filter((email) => email.length > 0)

  const validate = (): string | true => {
    if (filteredEmails.length === 0 && bmId === "") {
      return "Please provide at least one member email or ball machine"
    }
    return true
  }

  const isValid = validate() === true

  const handleSubmit = () => {
    onSubmit({ emails: filteredEmails, bmId })
  }

  const renderButtons = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )
    }

    return (
      <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!isValid}>
          Submit
        </Button>
      </div>
    )
  }

  return (
    <Form>
      <Form.Group className="" controlId="reserve-email">
        <Form.Label className="d-flex align-items-center justify-content-between">
          <div className="fw-bold">Add Player(s):</div>
          {!bmId && emails.length < maxPlayers && (
            <Button
              variant="link"
              size="sm"
              className="my-0 py-0"
              onClick={() => setEmails((emails) => [...emails, ""])}
            >
              Add player
            </Button>
          )}
        </Form.Label>
        {emails.map((email, index) => (
          <InputGroup className="mb-1" key={index}>
            <Form.Control
              type="email"
              value={email}
              placeholder="STC member email"
              disabled={bmId !== ""}
              onChange={(e) => {
                const values = [...emails]
                values[index] = e.target.value.trim()
                setEmails(values)
              }}
            />
            {emails.length > 1 && (
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() =>
                  setEmails((emails) => emails.filter((_, i) => i !== index))
                }
              >
                x
              </Button>
            )}
          </InputGroup>
        ))}
      </Form.Group>
      {allowBm && (
        <Form.Group className="my-3" controlId="bm">
          <Form.Label className="fw-bold">Add Ball Machine:</Form.Label>
          {bmItems.map((bm) => (
            <Form.Check
              label={bm.name + (bm.in_service ? "" : " (not in service)")}
              type="checkbox"
              id={`chk-${bm.id}`}
              name="bm"
              value={bm.id}
              checked={bmId === bm.id}
              disabled={!bm.in_service}
              onChange={() => {
                setBmId(bmId === bm.id ? "" : bm.id)
                setEmails([""])
              }}
              key={bm.id}
            />
          ))}
          <Form.Text id="bmPlayerHelpBlock" muted>
            Adding a ball machine will disable adding players.
          </Form.Text>
        </Form.Group>
      )}
      {renderButtons()}
    </Form>
  )
}

export default JoinReservationForm
