import React, { createContext, useReducer, useState } from "react"
import "./App.css"
import Layout from "./components/Layout"
import Reservations from "./components/Reservations"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import {
  AuthAction,
  authInitialState,
  authReducer,
  AuthState,
} from "./reducer/authReducer"
import moment from "moment"
import { M_DATE_FORMAT } from "./lib/utils"

const queryClient = new QueryClient()

export const AuthContext = createContext<AuthState>(authInitialState)
export const AuthDispatchContext = createContext<React.Dispatch<AuthAction>>(
  () => {}
)

const App = () => {
  const [auth, dispatch] = useReducer(authReducer, authInitialState)
  const [date, setDate] = useState<string>(moment().format(M_DATE_FORMAT))

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={auth}>
        <AuthDispatchContext.Provider value={dispatch}>
          <DateContext.Provider value={{ date, setDate }}>
            <Layout>
              <Reservations />
            </Layout>
          </DateContext.Provider>
        </AuthDispatchContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  )
}

export const DateContext = createContext<{
  date: string
  setDate: (date: string) => void
}>({
  date: "",
  setDate: () => {},
})

export default App
