import { FC } from "react"
import { Accordion, Button, Stack } from "react-bootstrap"

interface Props {
  onDone: () => void
}

const content = [
  {
    title: "How to use the STC Courts App",
    content: [
      "STC Courts is a web app that lets you reserve court time up to a week in advance for up to 4 players. You can also reserve court time with a ball machine.",
    ],
  },
  {
    title: "User account",
    content: [
      "Create and sign in to your STC account using the Sign-In button on the top right. If you manage your email through Gmail, sign in with your Google account. When creating an account, use the same email you registered with STC.",
      "You don't need to be signed in to use the STC Courts App, however it has some advantages if you are.",
    ],
  },
  {
    title: "How to reserve court time",
    content: [
      "Select a date from the drop-down on the top left and click the “Reserve” button in the slot you want to reserve. You can add up to 4 players or select a ball machine, and choose a duration (30mins to 2h). If signed in, your email will be pre-filled.",
    ],
  },
  {
    title: "How to cancel your reservation",
    content: [
      "Click the “Cancel” link beside your name in the slot you want to cancel. If signed in, no code is needed. Otherwise, provide the cancellation code emailed with the reservation.",
      "You can also cancel for other players in the same group with the same code.",
    ],
  },
  {
    title: "How to join a reservation",
    content: [
      "Join a reservation by clicking the “Join” button in the relevant time slot, provided there are fewer than 4 players and no ball machine reservation.",
    ],
  },
  {
    title: "Give us feedback",
    content: [
      "We welcome your feedback! Submit comments, questions, or bug reports via the Contact Form above.",
    ],
  },
]

const Howto: FC<Props> = ({ onDone }) => {
  const renderAccordion = () => (
    <Accordion defaultActiveKey="0">
      {content.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>
            <strong>{item.title}</strong>
          </Accordion.Header>
          <Accordion.Body>
            <Stack gap={2}>
              {item.content.map((content, index) => (
                <div key={index}>{content}</div>
              ))}
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  )

  const renderPlain = () =>
    content.map((item, index) => (
      <div key={index} className="mb-3">
        <h5>{item.title}</h5>
        <Stack gap={2}>
          {item.content.map((content, index) => (
            <div key={index}>{content}</div>
          ))}
        </Stack>
      </div>
    ))

  return (
    <div>
      <div className="d-none d-md-block">{renderPlain()}</div>
      <div className="d-block d-md-none">{renderAccordion()}</div>
      <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <Button variant="primary" onClick={onDone}>
          Close
        </Button>
      </div>
    </div>
  )
}

export default Howto
