import { FC } from "react"
import { SlotGcalEvent } from "../lib/types"
import { getFormattedSlotTime } from "../lib/utils"

interface Props {
  slot: number
  event: SlotGcalEvent
}

const EventSlot: FC<Props> = ({ slot, event }) => {
  const renderSlotInfo = () => {
    const eventInfo = event.data.short_info || event.data.info || "Club Event"
    return <div className="mt-1 badge text-bg-warning">{eventInfo}</div>
  }

  return (
    <tr key={slot}>
      <td>{getFormattedSlotTime(event.start_time, event.end_time)}</td>
      <td>{renderSlotInfo()}</td>
    </tr>
  )
}

export default EventSlot
