import { Dropdown, Stack } from "react-bootstrap"
import { LuCalendar1 } from "react-icons/lu"
import useDate from "../hooks/useDate"
import { useEffect } from "react"
import moment from "moment"
import { M_DATE_FORMAT } from "../lib/utils"

const DateMenu = () => {
  const { date, mDate, dateFmt, dayOfWeek, setDate, dates } = useDate()

  // const queryClient = useQueryClient()

  // const handleRefresh = async () => {
  //   await fetch(`${config.apiBaseUrl}/reservations/${date}?refresh=true`, {
  //     headers: {
  //       Accept: "application/json",
  //     },
  //   })
  //   queryClient.invalidateQueries({ queryKey: ["reservations"] })
  // }

  // check the date when browser regains focus
  useEffect(() => {
    const checkDate = () => {
      if (document.visibilityState === "visible") {
        // check the date and set it to today if it's in the past
        const today = moment().startOf("day")
        if (mDate.isBefore(today)) {
          setDate(today.format(M_DATE_FORMAT))
        }
      }
    }

    const controller = new AbortController()
    window.addEventListener("visibilitychange", checkDate, {
      signal: controller.signal,
    })
    window.addEventListener("focus", checkDate, {
      signal: controller.signal,
    })

    return () => {
      // Calling `.abort()` removes ALL event listeners
      // associated with `controller.signal`. Gone!
      controller.abort()
    }
  }, [mDate, setDate])

  return (
    <Stack direction="horizontal" gap={2} className="">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-date"
          className="d-flex align-items-center gap-2 text-nowrap"
        >
          <Stack direction="horizontal" gap={2} className="fw-semibold">
            <LuCalendar1 />
            <div>{dayOfWeek}</div>
            <div className="vr" />
            <div>{dateFmt}</div>
          </Stack>
        </Dropdown.Toggle>

        <Dropdown.Menu className="border-2 border-primary">
          {dates.map((dateObj, key) => (
            <Dropdown.Item
              key={key}
              href="#"
              active={dateObj.date === date}
              onClick={() => setDate(dateObj.date)}
            >
              <div className="d-flex align-items-center justify-content-between gap-3 text-nowrap">
                <div className="small">{dateObj.mDate.format("dddd")}</div>
                <div>{dateObj.mDate.format("D MMM YYYY")}</div>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Stack>
  )
}

export default DateMenu
