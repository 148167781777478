import { FC, useContext, useState } from "react"
import { Court, SlotReservation, SlotsConfig } from "../lib/types"
import {
  getFormattedSlotStartTime,
  getFormattedSlotTime,
  getFreeSlots,
  getReservationName,
  getSlotTime,
  isBmUsed,
  isSlotInPast,
} from "../lib/utils"
import { Button, Stack } from "react-bootstrap"
import AddReservationDialog from "./Dialog/AddReservationDialog"
import CancelReservationDialog from "./Dialog/CancelReservationDialog"
import { ReservationContext } from "./Reservations"
import JoinReservationDialog from "./Dialog/JoinReservationDialog"
import { PiTennisBall } from "react-icons/pi"

interface Props {
  slot: number
  court: Court
  date: string
  usedSlots: Set<number>
  slotsDef: SlotsConfig
  slotReservations: SlotReservation[]
}

const Slot: FC<Props> = ({
  slot,
  slotsDef,
  slotReservations,
  court,
  date,
  usedSlots,
}) => {
  // calculate how many slots are available (this + following ones)
  const maxSlots = Math.min(
    getFreeSlots(slotsDef, slot, usedSlots),
    slotsDef.max_slots
  )
  const slotTime = getSlotTime(slotsDef, slot)
  const isInPast = isSlotInPast(slotsDef, slot, date)

  const [showReserveDialog, setShowReserveDialog] = useState(false)
  const [showJoinDialog, setShowJoinDialog] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const ctxReservation = useContext(ReservationContext)

  const handleReserveClick = () => {
    setShowReserveDialog(true)
  }

  const handleReserveCancelClick = (reservationId: string) => {
    ctxReservation.setReservationId(reservationId)
    setShowCancelDialog(true)
  }

  const handleReserveDialogClose = () => {
    setShowReserveDialog(false)
  }

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false)
  }

  const handleJoinClick = () => {
    setShowJoinDialog(true)
  }

  const handleJoinDialogClose = () => {
    setShowJoinDialog(false)
  }

  const renderEmpty = () => {
    if (isInPast) return null
    return (
      <tr key={slot}>
        <td>{getFormattedSlotStartTime(slotTime[0])}</td>
        <td>
          <Button variant="success" size="sm" onClick={handleReserveClick}>
            Reserve
          </Button>
          <AddReservationDialog
            show={showReserveDialog}
            maxSlots={maxSlots}
            slot={slot}
            slotsDef={slotsDef}
            date={date}
            courtId={court.id}
            courtLabel={court.label}
            showBallMachines={true}
            onClose={handleReserveDialogClose}
            onDone={handleReserveDialogClose}
          />
        </td>
      </tr>
    )
  }

  const renderBm = (reservation: SlotReservation) => {
    const bm = ctxReservation.bmItems.find((bm) => bm.id === reservation.bm_id)
    if (!bm) return null

    return (
      <div className="text-muted">
        <Stack direction="horizontal" gap={1}>
          <PiTennisBall />
          <div>{bm.name}</div>
        </Stack>
      </div>
    )
  }

  const renderJoin = () => {
    if (isInPast || slotReservations.length > 3 || isBmUsed(slotReservations))
      return null

    return (
      <Button
        variant="success"
        size="sm"
        className="mt-1"
        onClick={handleJoinClick}
      >
        Join
      </Button>
    )
  }

  const renderBooking = () => {
    return slotReservations.map((reservation, key) => (
      <div key={key} className={isInPast ? "text-muted" : ""}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fw-bold">{getReservationName(reservation)}</div>
          {!isInPast && (
            <Button
              variant="link"
              onClick={() => {
                handleReserveCancelClick(reservation.id)
              }}
              size="sm"
              className="my-0 py-0 text-decoration-none"
            >
              Cancel
            </Button>
          )}
        </div>
        {renderBm(reservation)}
      </div>
    ))
  }

  if (slotReservations.length === 0) {
    return renderEmpty()
  }

  return (
    <tr key={slot} className={isInPast ? "text-muted small" : ""}>
      <td>
        {getFormattedSlotTime(
          slotReservations[0].start_time,
          slotReservations[0].end_time
        )}
      </td>
      <td>
        {renderBooking()}
        {renderJoin()}
        <CancelReservationDialog
          show={showCancelDialog}
          slotReservations={slotReservations}
          courtLabel={court.label}
          onClose={handleCancelDialogClose}
          onDone={handleCancelDialogClose}
        />
        <JoinReservationDialog
          show={showJoinDialog}
          slotReservations={slotReservations}
          date={date}
          courtId={court.id}
          courtLabel={court.label}
          onClose={handleJoinDialogClose}
          onDone={handleJoinDialogClose}
        />
      </td>
    </tr>
  )
}

export default Slot
