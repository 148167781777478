import { FC, useState } from "react"
import { Alert, Modal } from "react-bootstrap"
import { AddReservationRequest, SlotsConfig } from "../../lib/types"
import useAddReservation from "../../hooks/useAddReservation"
import { useQueryClient } from "@tanstack/react-query"
import AddReservationForm, { FormValues } from "../Form/AddReservationForm"

interface Props {
  show: boolean
  maxSlots: number
  slot?: number
  slotsDef: SlotsConfig
  date: string
  courtId: string
  courtLabel: string
  subLabel?: string
  showBallMachines?: boolean
  onClose: () => void
  onDone: () => void
}

const AddReservationDialog: FC<Props> = ({
  show,
  onClose,
  onDone,
  maxSlots,
  slot = 0,
  slotsDef,
  date,
  courtId,
  courtLabel,
  subLabel = null,
  showBallMachines = false,
}) => {
  const addReservation = useAddReservation()
  const queryClient = useQueryClient()

  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = (values: FormValues) => {
    setError(null)

    // assemble form data - if maxSlots is 0, then we don't need to pass the slot_start/end
    const data: AddReservationRequest = {
      date,
      court_id: courtId,
      emails: values.emails,
      bm_id: values.bmId,
    }
    if (maxSlots > 0) {
      data.slot_start = slot
      data.slot_end = slot + values.slots - 1
    }

    addReservation.mutate(data, {
      onSuccess: async (data, variables, context) => {
        const res = await data.json()
        if (res.success !== undefined && !res.success) {
          setError(res.message)
          return
        }
        // success
        queryClient.invalidateQueries({
          queryKey: ["reservations", { date }],
        })
        onDone()
      },
      onError: (error, variables, context) => {
        setError(error.message)
      },
    })
  }

  const handleOnShow = () => {
    setError(null)
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      onShow={handleOnShow}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Reservation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger" className="fw-bold">
            {error}
          </Alert>
        )}

        <Alert variant="primary" className="fw-bold w-100">
          <div className="mt-2 mb-2 d-flex align-items-center justify-content-between">
            <div>{courtLabel}</div>
            {subLabel && <div className="text-muted">{subLabel}</div>}
          </div>
        </Alert>

        <AddReservationForm
          slot={slot}
          maxSlots={maxSlots}
          slotsDef={slotsDef}
          allowMultipleEmails={maxSlots > 0}
          showBallMachines={showBallMachines}
          isLoading={addReservation.isPending}
          onSubmit={handleFormSubmit}
          onCancel={onClose}
        />
      </Modal.Body>
    </Modal>
  )
}

export default AddReservationDialog
