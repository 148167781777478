import { getAccessToken } from "../lib/auth-utils"

enum AuthActionKind {
  SET_DATA = "SET_TOKEN",
  REMOVE_DATA = "REMOVE_TOKEN",
}

// An interface for our actions
export interface AuthAction {
  type: AuthActionKind
  payload: AuthState | null
}

// An interface for our state
export interface AuthState {
  token: string | null
  username: string | null
  user_id: string | null
  member_id: string | null
}

export const authInitialState: AuthState = {
  token: getAccessToken(),
  username: null,
  user_id: null,
  member_id: null,
}

const emptyState: AuthState = {
  token: null,
  username: null,
  user_id: null,
  member_id: null,
}

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionKind.SET_DATA:
      return {
        ...emptyState,
        ...action.payload,
      }
    case AuthActionKind.REMOVE_DATA:
      return emptyState
    default:
      return state
  }
}

export { AuthActionKind, authReducer }
