import Court from "./Court"
import useReservations from "../hooks/useReservations"
import { getSlots } from "../lib/utils"
import { BmItem, SlotGcalEvent, SlotReservation } from "../lib/types"
import useConfig from "../hooks/useConfig"
import { createContext, useState } from "react"
import Event from "./Event"
import useBallMachine from "../hooks/useBallMachine"
import { Col, Row } from "react-bootstrap"
import useDate from "../hooks/useDate"

const Reservations = () => {
  const [ctxReservationId, ctxSetReservationId] = useState<string>("")

  const { data: config, isLoading: isLoadingConfig } = useConfig()
  const { data: bmItems } = useBallMachine()
  const { date } = useDate()

  const { data: reservations, isLoading: isLoadingReservations } =
    useReservations(date, config !== undefined)

  if (isLoadingConfig) {
    return <div>Loading...</div>
  }

  if (!config) {
    return (
      <div className="alert alert-danger fw-bold" role="alert">
        Error loading config
      </div>
    )
  }

  const renderCourts = () => {
    if (isLoadingReservations) {
      return <div>Loading...</div>
    }

    if (!reservations || reservations.error) {
      return (
        <div>
          <div className="alert alert-danger fw-bold" role="alert">
            Error loading reservations
            {reservations?.error ? `: ${reservations.error}` : ""}
          </div>
        </div>
      )
    }

    // patch events to include slots
    const slotEvents: SlotGcalEvent[] = reservations.gcal_events.map(
      (event) => {
        const slots = getSlots(config.slots, event.start_time, event.end_time)
        return {
          ...event,
          slots,
        }
      }
    )

    // patch reservations to include slots
    const slotReservations: SlotReservation[] = reservations.reservations.map(
      (reservation) => {
        const slots = getSlots(
          config.slots,
          reservation.start_time,
          reservation.end_time
        )
        return {
          ...reservation,
          slots,
        }
      }
    )

    return config.courts.map((court) => (
      <Col md={12} lg={6} xl={4} className="mt-3" key={court.id}>
        <Court
          court={court}
          events={slotEvents}
          reservations={slotReservations}
          slotsDef={config.slots}
        />
      </Col>
    ))
  }

  const renderEvents = () => {
    if (!reservations) return null

    // render only special events
    const events = reservations.gcal_events.filter(
      (event) => event.is_special_event
    )

    return (
      <Col md={12} lg={6} xl={4} className="mt-3">
        {events.map((event, index) => (
          <Event
            key={index}
            event={event}
            reservations={reservations.reservations}
            slotsDef={config.slots}
          />
        ))}
      </Col>
    )
  }
  return (
    <Row>
      <ReservationContext.Provider
        value={{
          bmItems: bmItems || [],
          reservationId: ctxReservationId,
          setReservationId: ctxSetReservationId,
        }}
      >
        {renderCourts()}
        {renderEvents()}
      </ReservationContext.Provider>
    </Row>
  )
}

export const ReservationContext = createContext<{
  bmItems: BmItem[]
  reservationId: string
  setReservationId: (id: string) => void
}>({
  bmItems: [],
  reservationId: "",
  setReservationId: () => {},
})

export default Reservations
