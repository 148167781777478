import { useMutation } from "@tanstack/react-query"
import config from "../config"
import { CancelReservationRequest } from "../lib/types"
import { getFetchHeaders } from "../lib/auth-utils"

const cancelReservation = (data: CancelReservationRequest) => {
  const request = new Request(config.apiBaseUrl + "/cancel", {
    method: "POST",
    headers: getFetchHeaders(),
    body: JSON.stringify(data),
  })
  return fetch(request)
}

const useCancelReservation = () => {
  return useMutation({
    mutationFn: cancelReservation,
  })
}

export default useCancelReservation
