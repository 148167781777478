import { useQuery } from "@tanstack/react-query"
import config from "../config"
import { ReservationResponse } from "../lib/types"
import { getFetchHeaders } from "../lib/auth-utils"

export const fetchReservations = async (
  date: string
): Promise<ReservationResponse> => {
  const response = await fetch(`${config.apiBaseUrl}/reservations/${date}`, {
    headers: getFetchHeaders(),
  })

  if (!response.ok) {
    throw new Error("Failed to fetch reservations")
  }

  return response.json()
}

const useReservations = (date: string, enabled: boolean) => {
  return useQuery({
    queryKey: ["reservations", { date }],
    queryFn: () => fetchReservations(date),
    enabled,
  })
}

export default useReservations
