import { useMutation } from "@tanstack/react-query"
import config from "../config"
import { getFetchHeaders } from "../lib/auth-utils"

const sendFeedback = (data: FormData) => {
  const request = new Request(config.apiBaseUrl + "/feedback", {
    method: "POST",
    headers: getFetchHeaders(),
    body: JSON.stringify(Object.fromEntries(data)),
  })
  return fetch(request)
}

const useSendFeedback = () => {
  return useMutation({
    mutationFn: sendFeedback,
  })
}

export default useSendFeedback
